// Global scss
import './global/styles/global_styles/global_styles.scss';

// Global css
const GlobalCssFiles = require.context('./global/styles/variables/css/', true);
GlobalCssFiles.keys().forEach(GlobalCssFiles);

// Main scripts
import './global/scripts/behaviour';

// import './global/scripts/browserFeatures';
import './global/scripts/scrolledBehaviour';
import './global/scripts/blocks';

// Fonts
import './global/fonts/icons/iconfont.css';
import './global/fonts/roboto/stylesheet.css';
import './global/fonts/gilroy/stylesheet.css';


// Components
const componentFiles = require.context('./elements', true, /^(?!.*_.*).*\.(scss|js|ts)$/);
componentFiles.keys().forEach(componentFiles);
