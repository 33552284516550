const CookieNoticeBehaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('#cookie-notice');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach((block) => init(block));
    };

    const init = function (block: HTMLElement) {
        const title = block.querySelector('h4');

        const textContainer = document.getElementById('cn-notice-text');
        const revokeButton = document.getElementById('cn-refuse-cookie');
        const acceptButton = document.getElementById('cn-accept-cookie');
        const moreInfoButton = document.getElementById('cn-more-info');

        if (textContainer && title) {
            textContainer.prepend(title);
        }

        if (textContainer && moreInfoButton) {
            moreInfoButton.className = '';
            textContainer.append(' ');
            textContainer.append(moreInfoButton);
        }

        if (acceptButton) {
            const acceptButtonText = acceptButton.innerText;
            acceptButton.classList.add('styledButton--iconPositionRight');
            acceptButton.innerHTML = `<span class="styledButton__content">${acceptButtonText}<span class="styledButton__icon global-icon global-icon-arrow-right"></span></span>`;
            acceptButton.innerHTML += `<span class="styledButton__hoverContent">${acceptButtonText}<span class="styledButton__icon global-icon global-icon-arrow-right"></span></span>`;
        }

        if (revokeButton) {
            const revokeButtonText = revokeButton.innerText;
            revokeButton.classList.add('styledButton--iconPositionRight');
            revokeButton.innerHTML = `<span class="styledButton__content">${revokeButtonText}<span class="styledButton__icon global-icon global-icon-arrow-right"></span></span>`;
            revokeButton.innerHTML += `<span class="styledButton__hoverContent">${revokeButtonText}<span class="styledButton__icon global-icon global-icon-arrow-right"></span></span>`;
        }
    };

    return {
        attach: attach,
    };
})();

behaviours.addBehaviour(CookieNoticeBehaviour);
