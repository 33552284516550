import { Behaviour } from '../../../global/scripts/behaviour';
import {SwiperModule} from "swiper/types";


const blockArticlesSliderBehaviour: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.blockArticles');

        if (blocks.length === 0) {
            return;
        }

        import(/* webpackChunkName: "slider" */ "swiper").then(module => {
            blocks.forEach(block => init(block, module));
        });
    };

    const init = function (block: HTMLElement, module: any) {
        const Swiper = module.default as any;
        const ScrollbarModule = module.Scrollbar as SwiperModule;
        const EffectModule = module.EffectCreative as SwiperModule;

        const baseClass = 'blockArticles';
        const sliderElement = block.querySelector<HTMLElement>(`.${baseClass}__slider`);
        const wrapperClass = `${baseClass}__items`;
        const slideClass = `${baseClass}__item`;

        if (!sliderElement) {
            return false;
        }

        const swiper = new Swiper(sliderElement, {
            modules: [ScrollbarModule, EffectModule],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            speed: 400,
            slidesPerView: 1,
            spaceBetween: 12,
            slideActiveClass: `${baseClass}__item--active`,
            slideVisibleClass: `${baseClass}__item--visible`,
            watchSlidesProgress: true,
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
            },
            breakpoints: {
                768: {
                    spaceBetween: 25,
                    slidesPerView: 2,
                },
                1024: {
                    spaceBetween: 39,
                    slidesPerView: 3,
                },
            },
        });
    };

    return {
        attach: attach,
    };
})();

behaviours.addBehaviour(blockArticlesSliderBehaviour);
