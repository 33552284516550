(function ($) {
    const datePickerOptions = {
        suppressDatePicker: true,
        minDate: '0',
        changeMonth: false,
        changeYear: false,
        closeText: 'sluiten',
        closeStatus: 'Onveranderd sluiten ',
        prevText: '<vorige',
        prevStatus: 'Zie de vorige maand',
        nextText: 'volgende>',
        nextStatus: 'Zie de volgende maand',
        currentText: 'Huidige',
        currentStatus: 'Bekijk de huidige maand',
        monthNames: [
            'januari',
            'februari',
            'maart',
            'april',
            'mei',
            'juni',
            'juli',
            'augustus',
            'september',
            'oktober',
            'november',
            'december',
        ],
        monthNamesShort: ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        monthStatus: 'Bekijk een andere maand',
        yearStatus: 'Bekijk nog een jaar',
        weekHeader: 'Sm',
        weekStatus: '',
        dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
        dayNamesShort: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
        dayNamesMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
        dayStatus: 'Gebruik DD als de eerste dag van de week',
        dateStatus: 'Kies DD, MM d',
        initStatus: 'Kies een datum',
        isRTL: false,
        beforeShow: function (e) {
            return !Form.hasTouch();
        },
        onUpdateDatepicker: function (e) {
            if (e.input.closest('.blockForm--textColorDark').length) {
                e.dpDiv.addClass('blockForm--textColorDark');
            }
        },
        onSelect: function (value, e) {
            Form.focusBlurInput(e.input[0], false);
        },
    };

    class Form {
        // Init all functions
        static initAll(context)
        {
            context.querySelectorAll('.form').forEach((form) => {
                if (form.classList.contains('attached')) {
                    return;
                }
                form.classList.add('attached');
                Form.initSelect(form);
                Form.initDatePicker(form);
                Form.formElementStates(form);
                Form.toggleExpandableSelect(form);
            });
        }

        // Init all functions
        static initAfterRender(context)
        {
            Form.initSelect(context);
            Form.initDatePicker(context);
            Form.formElementStates(context);
        }

        static hasTouch = () => {
            return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
        };

        static toggleExpandableSelect()
        {
            $(document).on('gform_post_render', (e) => {
                $(".checkboxesExpandable legend").click(function () {
                    $(this).parent().toggleClass("checkboxesExpanded");
                });
            });

        }

        static focusBlurInput(input, focussed)
        {
            const field = input.closest('.gfield');
            if (!field) {
                return false;
            }
            let up = focussed || !!input.value.trim() || !!input.placeholder;
            up = (Form.hasTouch() && input.type === 'select-one') || up;
            field.classList.toggle('gfield--up', up);
        }

        static initSelect(context)
        {
            context.querySelectorAll('select').forEach((element) => {
                if (element.classList.contains('disable-select2') || element.classList.contains('sf-input-select')) {
                    return;
                }

                const $element = $(element);
                let placeholder;
                if (!element.parentElement.id) {
                    const id = `select-${new Date().valueOf()}`;
                    element.parentElement.id = id;
                }

                const options = element.querySelectorAll('option');
                if (
                    options[0] &&
                    !options[0].classList.contains('gf_placeholder') &&
                    options[0].value !== '' &&
                    element.getAttribute('aria-required') !== 'true'
                ) {
                    const option = document.createElement('option');
                    element.prepend(option);
                    placeholder = '';
                } else {
                    options[0].disabled = Form.hasTouch() && element.getAttribute('aria-required') !== 'true';
                    placeholder = options[0].text;
                }

                if (!Form.hasTouch()) {
                    let disabled = element.classList.contains('sf-input-select') && element.querySelectorAll('option').length <= 1;
                    disabled = element.hasAttribute('disabled') || disabled;
                    $element
                        .select2({
                            disabled: disabled,
                            minimumResultsForSearch: 100,
                            dropdownParent: element.parentElement,
                            placeholder: placeholder,
                        })
                        .on('select2:select', () => {
                            Form.focusBlurInput(element, false);
                        });
                }
            });
        }

        static formElementStates(context)
        {
            context.querySelectorAll('input, textarea, select').forEach((element) => {
                Form.focusBlurInput(element, false);
                element.addEventListener('focus', (e) => Form.focusBlurInput(e.currentTarget, true));
                element.addEventListener('blur', (e) => Form.focusBlurInput(e.currentTarget, false));
                element.addEventListener('change', (e) => Form.focusBlurInput(e.currentTarget, false));
            });
        }

        static initDatePicker(context)
        {
            if (typeof jQuery.datepicker !== 'undefined' && typeof gform === 'undefined') {
                $('input.datepicker', context).datepicker(datePickerOptions);
            }

            context.querySelectorAll('input.datepicker').forEach((element) => {
                if (Form.hasTouch()) {
                    const date = new Date();
                    date.setDate(date.getDate() + 1);
                    const dateString = `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`;
                    element.value = dateString;
                    element.type = 'date';
                    element.min = dateString;
                    Form.focusBlurInput(element, false);
                    element.closest('.ginput_container').classList.remove('ginput_container_date');
                } else {
                    element.placeholder = '';
                }
            });
        }
    }

    if (typeof gform !== 'undefined' && typeof gform.addFilter !== 'undefined') {
        gform.addFilter('gform_datepicker_options_pre_init', function (optionsObj, formId, fieldId) {
            return { ...optionsObj, ...datePickerOptions };
        });
    }

    document.addEventListener('DOMContentLoaded', function () {
        $('.gform_drop_instructions').text('Bestanden slepen');
        $('.gform_button_select_files').text('Bestand kiezen');
    });

    /**
     * Form behaviour
     */
    FormBehaviour = {
        attach: function (context) {
            Form.initAll(context);
        },
    };

    behaviours.addBehaviour(FormBehaviour);

    $(document).on('gform_post_render', (e) => {
        Form.initAfterRender(e.target);
    });
})(jQuery);
