behaviours.addBehaviour(
    (() => {
        const capitalizeFirstLetter = (string: any) => string.charAt(0).toUpperCase() + string.slice(1);
        const attach = function () {
            document.querySelectorAll('div[data-background]').forEach((block) => {
                const background = block.getAttribute('data-background');
                const nextBlock = block.nextElementSibling;
                const previousBlock = block.previousElementSibling;

                if (!previousBlock?.hasAttribute('data-background' || !background)) {
                    return;
                }

                previousBlock.setAttribute('data-next-block', block.classList[0]);


                if (!nextBlock?.hasAttribute('data-background') || !previousBlock) {
                    return;
                }

                if (block.getAttribute('data-pull-background') === 'top' || block.getAttribute('data-pull-background') === 'bottom') {
                    if (previousBlock && previousBlock.hasAttribute('data-background')) {
                        block.classList.add(`${block.classList[0]}--backgroundTop${capitalizeFirstLetter(previousBlock.getAttribute('data-background'))}`);

                        if(previousBlock.classList.contains('hero--withImage')) {

                            let blockBG =  document.querySelector<HTMLElement>(`.${previousBlock.classList[0]}__background`);
                            let blockBGImage =  document.querySelector<HTMLElement>(`.${previousBlock.classList[0]}__imageWrapper`);
                            let blockBottomGradient = document.querySelector<HTMLElement>(`.${previousBlock.classList[0]}__bottomGradient`)
                            let blockHeight = (block as HTMLElement).offsetHeight;

                            const calculateBG = () => {
                                if(!blockBGImage || !blockBG || !blockBottomGradient) {
                                    return;
                                }
                                let previousBlockHeight = (previousBlock as HTMLElement).offsetHeight;

                                blockBG.style.height = blockHeight + previousBlockHeight + 'px';
                                blockBGImage.style.height = blockHeight + previousBlockHeight + 'px';
                                blockBottomGradient.style.bottom = '-' + blockHeight + 'px';
                                blockBottomGradient.style.height = blockHeight * 1.5 + 'px';
                            }
                            onresize = (event) => calculateBG();
                            calculateBG();
                        }
                    }
                    if (nextBlock && nextBlock.hasAttribute('data-background')) {
                        block.classList.add(`${block.classList[0]}--backgroundBottom${capitalizeFirstLetter(nextBlock.getAttribute('data-background'))}`);

                    }
                }
                const nextBackground = nextBlock.getAttribute('data-background') ?? '';

                block.setAttribute('data-next-background', nextBackground);
                previousBlock.setAttribute('data-next-background', background);
            });
        };

        return {
            attach: attach,
        };
    })(),
);
