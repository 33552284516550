import { Behaviour } from './behaviour';

/**
 * Scroll behaviour
 */
const scrolledBehaviour: () => Behaviour = () => {
    const attach = (context: HTMLElement) => {
        if (document.querySelector('body') && !context.querySelector('.docs')) {
            initOnScroll();
        }
    };

    const initOnScroll = () => {
        document.addEventListener('scroll', (event) => onScroll());
        onScroll();
    };

    const onScroll = () => {
        if (window.scrollY > 10) {
            document.documentElement.classList.add('scrolled');
        } else {
            document.documentElement.classList.remove('scrolled');
        }
    };

    return {
        attach,
    };
};

behaviours.addBehaviour(scrolledBehaviour());
